<template>
  <v-app>
    <v-app-bar
      v-if="getSignupAppBarVisibilityStatus"
      color="transparent"
      absolute
      app
      flat
    >
      <v-spacer />
      <c-language-setter style="max-width: 150px" />
    </v-app-bar>
    <snackbar />
    <pages-core-view />
    <app-dialog :component-list="dialogList" />
  </v-app>
</template>

<script>
import PagesCoreView from "./components/core/View";
import Snackbar from "@/lib/calendesk-js-library/components/Snackbar";
import dialogList from "@/components/Dialogs/dialogList";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";
import CLanguageSetter from "@/lib/calendesk-js-library/components/CLanguageSetter.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "PagesIndex",
  components: {
    CLanguageSetter,
    PagesCoreView,
    Snackbar,
    AppDialog: () =>
      import("@/lib/calendesk-js-library/components/dialogs/AppDialog"),
  },
  mixins: [sharedActions],
  data() {
    return {
      dialogList,
    };
  },
  computed: {
    ...mapGetters({
      getSignupAppBarVisibilityStatus: "setup/getSignupAppBarVisibilityStatus",
    }),
  },
  created() {
    this.setSignupAppBarVisibilityStatus(true);
    this.setPreloaderStatus(false);
  },
  methods: {
    ...mapActions({
      setSignupAppBarVisibilityStatus: "setup/setSignupAppBarVisibilityStatus",
      setPreloaderStatus: "setup/setPreloaderStatus",
    }),
  },
};
</script>
