<template>
  <c-language-select
    v-model="selectedLanguage"
    :label="$trans('c_language_select_label')"
    dense
    @input="handleLanguageChange"
  />
</template>
<script>
import Vue from "vue";
import CLanguageSelect from "@/views/dashboard/pages/Settings/components/CLanguageSelect.vue";

export default Vue.extend({
  components: { CLanguageSelect },
  data() {
    return {
      selectedLanguage: localStorage.locale,
    };
  },
  methods: {
    handleLanguageChange(language) {
      const url = new URL(window.location.href);
      const pathParts = url.pathname.split("/");

      // Update the first parameter after the domain with the new language code
      if (pathParts.length > 1) {
        pathParts[1] = language;
      } else {
        pathParts.push(language); // In case the URL doesn't have a language param
      }

      // Update the pathname with the new language
      url.pathname = pathParts.join("/");

      // Reload the page with the new URL
      window.location.href = url.href;
    },
  },
});
</script>
